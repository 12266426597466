//  Core

.alerts-alternate {
  &.MuiAlert-root {
    border-width: 2px;
    border-style: solid;
    @include border-radius($border-radius-sm);
    border-left-width: 6px;
  }
}

.app-alerts {
  &.MuiAlert-root {
    border-width: 1px;
    border-style: solid;
    border-radius: 8px;
    background-color: #FEF1F2;
    font-family: $font-family-manrope;
    padding: 10px 16px;
    display: flex;
    align-items: center;

    .MuiAlert-icon {
      color: #EB1B1B;
      padding: 0;
    }

    .MuiAlert-message {
      padding: 0;
    }

    .MuiIconButton-label {
      color: #EB1B1B;
    }
  }
}

.MuiSnackbar-root {
  z-index: 2500;

  .MuiSnackbarContent-root {
    color: $white;
  }
}

@each $color,
$value in $theme-colors {
  .toastr-#{$color} {
    .MuiSnackbarContent-root {
      background: $value;
      box-shadow: 0 0.313rem 0.8rem rgba($value, 0.5), 0 0.126rem 0.225rem rgba($value, 0.3) !important;
    }
  }
}

.MuiAlert-standardWarning {
  border-color: $warning;
}

.MuiAlert-standardError {
  border-color: $danger;
}

.MuiAlert-standardSuccess {
  border-color: $success;
}

.MuiAlert-standardInfo {
  border-color: $info;
}

@media (max-width: 767px){
  .app-alerts{
    &.MuiAlert-root{
      padding: 6px 8px;
      border-radius: 4px;
      .MuiAlert-icon{
        font-size: 18px;
        margin-right: 6px;
      }
      .MuiAlert-message{
        font-size: 12px;
      }
      .MuiIconButton-label{
        font-size: 16px;
      }
    }
    .MuiAlert-action{
      margin-right: 0;
    }
  }
}