//  Core

body {
  .modal-content {
    box-shadow: $modal-content-box-shadow-xs;
  }

  &.Model_Open {
    overflow: hidden !important;
    touch-action: none !important;
    -ms-touch-action: none !important;
  }
}

.MuiDialog-paper {

  .MuiDialogTitle-root {
    border-bottom: $gray-400 solid 1px;

    &>.MuiTypography-root {
      font-weight: bold;
      font-size: $font-size-lg;
    }

  }

  .MuiDialogActions-root {
    border-top: $gray-400 solid 1px;
  }

  &.modal-dark {
    color: $white;

    .MuiDialogContentText-root {
      color: $white;
    }

    .MuiDialogActions-root,
    .MuiDialogTitle-root {
      border-color: rgba(255, 255, 255, .1);
    }
  }

  &.app-overview-modal {
    font-family: $font-family-manrope;
    width: 1075px;
    // min-height: 576px;
    // max-height: 576px;
    padding: 0;
    overflow-y: hidden;

    .app-overview-modal-head {
      margin-bottom: 22px;

      h2 {
        font-family: $font-family-manrope;
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;
        color: #1D1B1B;
        margin-bottom: 0;
      }
    }

    .app-quotes-appr-box {
      background-color: $white;
      box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      border: none;

      .app-quotes-appr-box-header {
        padding: 12px 30px;
        border-bottom: 1px solid #F3F3F3;

        .card-header--title {
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          text-transform: uppercase;
          color: #9D9D9D;
        }
      }

      .app-quotes-appr-box-body {
        // height: 468px;
      }
    }

    .app-quotes-appr-card-content {
      padding: 12px;
    }

    .app-quotes-accordion {
      .card-box {
        border: 1px solid #E6E6E6;
        border-radius: 4px !important;
        background-color: $white;
        margin-bottom: 8px;
        padding: 0 20px;
        box-shadow: none;

        &:last-child {
          margin-bottom: 0;
        }

        &.error {
          border-color: rgba(235, 27, 27, 0.15);

          &.panel-open {
            border-color: rgba(235, 27, 27, 0.5);
          }

          .card-header {
            .app-quotes-accordion-pending-col {
              span {
                color: #EB1B1B;
              }

              i {
                visibility: visible;
              }
            }
          }
        }

        &.panel-open {
          border-color: #9D9D9D
        }

        .card-header {
          border-radius: 4px !important;
          background-color: transparent;
          border-bottom: 0;
          padding: 10px 0;

          .app-quotes-accordion-pending-col {
            span {
              font-size: 16px;
              font-weight: 600;
              line-height: 22px;
              color: #443E3E;
            }

            i {
              margin-left: 10px;
              width: 20px;
              height: 20px;
              flex: 0 0 20px;
              background-image: url('../../../assets/images/icons/info-danger.png');
              background-repeat: no-repeat;
              background-size: contain;
              visibility: hidden;
            }
          }

          .app-quotes-accordion-approver-col {
            h5 {
              font-size: 18px;
              font-weight: 600;
              line-height: 25px;
              color: #443E3E;
              margin-bottom: 4px;
            }

            p {
              font-size: 14px;
              line-height: 19px;
              color: #9D9D9D;
            }

          }

          .accordion-icon {
            width: 20px;
            height: 20px;
            background-image: url('../../../assets/images/icons/chevron-down.png');
            background-repeat: no-repeat;
            background-size: contain;
          }
        }

        .app-quotes-accordion-body {
          border-top: 1px solid rgba(29, 27, 27, 0.15);
          padding: 14px 0;
          font-size: 14px;
          line-height: 19px;
          color: #443E3E;
        }
      }
    }
  }

  .app-reqr-modal-chart-title {
    margin-top: 32px;
  }

  .app-reqr-modal-status-wrap {
    padding: 12px 20px 0;
    margin-top: 10px;
    margin-bottom: 24px;
    border-top: 2px solid #E6E6E6;
  }

  .app-reqr-modal-status {
    .app-reqr-modal-count {
      margin-top: 6px;

      .icon {
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-size: contain;
        display: block;

        &._thumb {
          background-image: url('../../../assets/images/icons/thumb-up-line-grey.png');
        }

        &._clock {
          background-image: url('../../../assets/images/icons/clock.png');
        }
      }

      span {
        font-size: 20px;
        line-height: 24px;
        padding-left: 6px;
        color: #1D1B1B;
        font-weight: 600;
      }
    }

    p {
      font-family: $font-family-manrope;
      font-size: 14px;
      line-height: 19px;
      font-weight: 500;
      color: #9D9D9D;
      margin-bottom: 0;
    }
  }

  .app-reqr-modal-chart-graph-col {
    padding: 20px 16px;
    border-right: 1px solid #E6E6E6;

    .app-alerts {
      padding: 13px 14px;
      color: #9D9D9D;
      box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      background-color: #FEF1F2;
      border: none;
    }

    .MuiAlert-icon {
      margin-right: 10px;
    }
  }

  .app-reqr-modal-chart-wrap {
    margin-top: 56px;
  }

  .app-reqr-modal-chart-content {
    background-color: #FAFAFA;
    padding: 20px 16px;
  }

  .app-reqr-modal-chart-content-inner {
    position: relative;

    .btn-close {
      position: absolute;
      right: 14px;
      top: 0;
    }
  }
}

@media (max-width: 767px) {
  .mobile-popup-close {
    top: -75 !important;
  }
}

@media (max-width: 1099px) {
  .mobile-popup {
    width: 50% !important;
  }

  .mobile-popup-close {
    top: -78% !important;
  }

  .mobile-popup-alert {
    //top:10% !important;
  }

  .MuiDialog-paper {
    &.app-overview-modal {
      width: 767px;
    }
  }
}

@media (max-width: 767px) {
  .MuiDialog-paperWidthLg {
    &.MuiDialog-paperScrollBody {
      // max-width: calc(100% - 24px) !important;
    }
  }

  .MuiDialog-paper {
    margin: 12px !important;

    .app-reqr-modal-chart-content {
      padding: 16px 12px;
    }

    .app-reqr-modal-chart-content-inner {
      .btn-close {
        padding: 0 !important;
      }
    }

    &.app-overview-modal {
      width: 100%;

      .app-overview-modal-head {
        margin-bottom: 16px;

        h2 {
          font-size: 16px;
          line-height: 24px;
        }
      }

      .app-quotes-appr-box {
        .app-quotes-appr-box-header {
          padding: 12px 20px;

          .w-40 {
            width: 35% !important;
          }

          .w-60 {
            width: 65% !important;
          }

          .card-header--title {
            font-size: 12px;
            line-height: 16px;
          }
        }
      }

      .app-quotes-appr-card-content {
        padding: 12px;
      }

      .app-quotes-accordion {
        .card-box {
          padding: 0 8px;

          .card-header {
            .app-quotes-accordion-pending-col {
              width: 35% !important;

              span {
                font-size: 12px;
                line-height: 16px;
              }

              i {
                margin-left: 5px;
                width: 16px;
                height: 16px;
                flex: 0 0 16px;
              }
            }

            .app-quotes-accordion-approver-col {
              width: 65% !important;

              h5 {
                font-size: 14px;
                line-height: 18px;
                margin-bottom: 4px;
              }

              p {
                font-size: 12px;
                line-height: 16px;
              }
            }

            .MuiButton-root {
              padding: 0 !important;
            }
          }

          .app-quotes-accordion-body {
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
    }
  }
}

@media (max-width: 345px) {
  .MuiDialog-paper {
    &.app-overview-modal {
      .app-overview-modal-head {
        h2 {
          font-size: 15px;
        }
      }
    }
  }
}