.bordered-card-box {
    border: 2px solid #F3F3F3;
    border-radius: 12px;
}

.mapboxgl-popup-content {
    border-radius: 12px !important;

    margin-left: -15px;
    padding: 16px;
}

.mapboxgl-popup-tip {
    //margin-left: 36px!important;
    padding-bottom: 34px !important;
    border-top-width: 10px !important;
    border-left-width: 10px !important;

    border-top-style: solid !important;
    border-left-style: solid !important;

    border-left-color: transparent !important;

}

.app-req-status-box {
    .card-box {
        height: 100%;
    }
}

.app-card-box {
    .card-header {
        padding: 1.25rem;

        .card-header--title {
            margin-left: 10px;
            font-size: 24px !important;
        }
    }
}

.col-1 {
    width: 180px;
}

.app-reqr-status-wrap {
    padding-top: 35px;
    margin-top: 27px;
    margin-bottom: 24px;
    border-top: 1px solid #E6E6E6;
}

.app-reqr-status {
    .app-reqr-count {
        margin-bottom: 11px;

        .icon {
            width: 16px;
            height: 16px;
            background-repeat: no-repeat;
            background-size: contain;
            display: block;

            &._thumb {
                background-image: url('../../../assets/images/icons/thumb-up-line.png');
            }

            &._clock {
                background-image: url('../../../assets/images/icons/clock.png');
            }
        }

        span {
            font-size: 20px;
            line-height: 24px;
            padding-left: 6px;
            color: #1D1B1B;
            font-weight: 600;
        }
    }

    p {
        font-family: $font-family-manrope;
        font-size: 14px;
        line-height: 19px;
        font-weight: 500;
        color: #9D9D9D;
        margin-bottom: 0;
    }
}

.app-reqr-chart-title {
    //margin-top: 20px;
}

.app-dashboard-map-wrap {
    .MuiCard-root {
        &.card-box {
            height: 100%;
        }

        .MuiCardContent-root {
            padding: 0;
            overflow: hidden;
            border-radius: 0 0 0.75rem 0.75rem;
        }
    }
}


.app-line-chart-title {
    font-family: $font-family-manrope;
    font-weight: 500;
    color: #443E3E;
    margin-top: 8px;

    .icon-error {
        width: 20px;
        height: 20px;
        flex: 0 0 20px;
        background-image: url('../../images/icons/error-warning-line.png');
        background-size: contain;
        background-repeat: no-repeat;
        margin-top: 2px;
        margin-right: 8px;
    }
}

.app-line-chart-title {
    font-family: $font-family-manrope;
    font-weight: 500;
    color: #443E3E;
    margin-top: 8px;

    .copyright-icon {
        width: 15px;
        height: 15px;
        flex: 0 0 20px;
        background-image: url('../../images/icons/copyright.png');
        background-size: contain;
        background-repeat: no-repeat;
        margin-top: 2px;
        margin-right: 8px;
    }
}

.cash-line-chart-title {

    background-image: url('../../images/icons/i_gray_icon.png');
    background-size: contain;
    background-repeat: no-repeat;
    margin-left: 5px;

}

.app-price-slider-cost.d-flex.flex-column {
    width: 16%;
}

.cursor_pointer {
    cursor: pointer;
}

.MuiCollapse-container.MuiCollapse-entered {
    cursor: pointer;
}

.MuiPaper-root.MuiCard-root.app-card-box.card-box.h-100.bordered-card-box.shadow-none.overflow-visible.MuiPaper-elevation1.MuiPaper-rounded .card-header.border-0 {
    padding-bottom: 0;
}

.MuiPaper-root.MuiCard-root.app-card-box.card-box.h-100.bordered-card-box.shadow-none.overflow-visible.MuiPaper-elevation1.MuiPaper-rounded .MuiCardContent-root {
    padding-top: 0;
}

.copyright-icon {
    margin-right: 0 !important;
    margin-top: 4px !important;
}

.app-pending-doc-drawer .app-pending-doc-drawer-body .app-doc-drawer-list .app-doc-drawer-list-item {
    box-shadow: 0px 2px 1px rgb(0 0 12 / 5%), 0px 0px 2px rgb(0 0 0 / 21%) !important;
}

.apexcharts-series.apexcharts-radial-series path,
.apexcharts-radialbar-track.apexcharts-track path {
    stroke-width: 1rem;
}

.MuiDialog-paper .app-reqr-modal-chart-graph-col .app-alerts {
    box-shadow: none !important;
    background-color: transparent !important;
    position: absolute;
}

.app-quotes-accordion-pending-col.d-flex.w-40 {
    display: flex;
    display: -webkit-flex;
    justify-content: start;
    -webkit-justify-content: start;
    flex-direction: row-reverse;
}

.app-quotes-accordion-pending-col.d-flex.w-40 .icon-info {
    margin-right: .3rem;
    margin-left: 0 !important;
}

.app-reqr-modal-count.d-flex.align-items-center.justify-content-center span {
    padding: 0;
}

.app-reqr-modal-count.d-flex.align-items-center.justify-content-center ._clock {
    margin-right: 8px;
}

.app-reqr-count.d-flex.align-items-center.justify-content-center ._clock {
    width: 20px !important;
    height: 20px !important;
}

.app-reqr-count.d-flex.align-items-center.justify-content-center ._thumb {
    margin: 0;
}

.MuiDialog-paper.app-overview-modal .app-quotes-accordion .card-box .card-header .app-quotes-accordion-pending-col i {
    margin-left: 0 !important;
}

._thumb {
    width: 20px !important;
    height: 20px !important;
    flex: 0 0 20px;
    background-image: url('../../images/icons/thumb-up-line-grey.png') !important;
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: 0px;
    margin-right: 8px;
    visibility: visible !important;
}

.w-250 {
    min-width: 250px !important;
}

.z-index-99 {
    z-index: 99999 !important;
}

span.d-flex.justify-content-center i.icon-error {
    transform: rotate(180deg);
    color: gray;
}

.MuiDialog-paper.app-overview-modal {
    margin: 0 !important;
}

a.bg-neutral-danger:hover,
a.bg-neutral-danger:focus,
button.bg-neutral-danger:hover,
button.bg-neutral-danger:focus {
    background-color: #fbc1c64d !important;
}

.app-kyc-popover {
    width: auto !important;
}

/* width */
.tab-item-wrapper::-webkit-scrollbar {
    width: 8px;
    cursor: pointer;
}

/* Track */
.tab-item-wrapper::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.043);
    border-radius: 10px;
    cursor: pointer;
}

/* Handle */
.tab-item-wrapper::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    cursor: pointer;
}

/* Handle on hover */
.tab-item-wrapper::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.15);
    cursor: pointer;
}

.pending-zero .apexcharts-series.apexcharts-radial-series path {
    display: none !important;


}

.desktop_kyc .app-header-kyc {
    display: flex !important;
}

.mobile_kyc .app-header-kyc {
    display: none !important;
}

.price-advantage-tooltip .MuiPopover-paper {
    // min-width: 300px !important;
    width: 35.5% !important;
    left: 61%!important;
    margin-top: 1rem !important;
}

// button.incomplete-hover:focus,
// button.complete-hover:focus {
//     background-color: #FEF1F2 !important;
// }

// .group-accordian {
//     max-height: 480px;
// }

// .cashflow_tooltip_icon{
//     &::after{
//         background-image: url('../../images/icons/error-warning-line.png');
//         background-size: contain;
//         background-repeat: no-repeat;
//     }
// }

// --------------------------------------------Media Query Start-----------------------------------------------------
@media (min-width: 1400px) {

    .classNew {
        height: 414px !important;
    }
}

@media (min-width: 1100px) {
    .mobile-popup {
        width: "50%" !important;
    }

    .classNew {
        width: 100%;
        height: 324px !important;
    }
}

@media (max-width: 991px) {

    .app-card-box {
        .card-header {
            .card-header--title {
                font-size: 24px !important;
                margin-left: 0;
            }
        }
    }
    .price-advantage-tooltip .MuiPopover-paper {
        width: 55.5% !important;
        left: 30% !important;}
}

@media (max-width: 767px) {
    .app-dashboard-row-1 {
        flex-direction: column-reverse;
    }

    .app-card-box {
        .card-header {
            flex-direction: column;
            align-items: flex-start;
            padding: 12px;

            .card-header--title {
                font-size: 16px !important;
                margin-left: 0;

                +.MuiCollapse-container {
                    margin-top: 12px;
                }

            }
        }
    }

    .app-req-status-box {
        .MuiCard-root {
            .MuiCardContent-root {
                padding: 0 12px;
            }
        }
    }

    .app-reqr-status-wrap {
        padding-top: 16px;
        margin-top: 16px;
        margin-bottom: 16px;
    }

    .app-reqr-status {
        .app-reqr-count {
            margin-bottom: 5px;

            span {
                font-size: 18px;
            }
        }

        p {
            font-size: 12px;
            line-height: 16px;
        }
    }

    .app-reqr-chart-title {
        font-size: 16px;
        //  margin-top: 16px;
    }

    .app-dashboard-main {
        .MuiGrid-spacing-xs-3 {
            >.MuiGrid-item {
                padding: 0 12px 16px;
            }
        }
    }

    .app-line-chart-title {
        font-size: 14px;

        .icon-error {
            width: 18px;
            height: 18px;
            flex: 0 0 18px;
        }
    }

    .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-3 {
        flex-direction: column-reverse;
    }

    .MuiGrid-root.app-dashboard-row-1.MuiGrid-container.MuiGrid-spacing-xs-3 {
        flex-direction: row;
    }

    .cash-line-chart-title.cursor_pointer {
        // display: none;
    }

    .cashflowtooltip {
        display: inline-block !important;
        top: 6px !important;
        padding: 1px !important;
    }

    body .app-kyc-popover-info {
        font-size: .8rem;
    }

    .app-reqr-modal-status-wrap.d-flex.align-items-center.justify-content-between,
    .app-reqr-modal-status-wrap.d-flex.align-items-center.justify-content-between {
        display: none !important;
    }

    .MuiDialog-paper .app-reqr-modal-chart-title {
        font-size: .9rem !important;
        margin: 0 !important;

    }

    .modalStatus {
        display: none !important;
    }

    .MuiPaper-root.MuiCard-root.app-card-box.card-box.h-100.bordered-card-box.shadow-none.overflow-visible.MuiPaper-elevation1.MuiPaper-rounded .MuiCardContent-root {
        padding: .4rem !important;
    }

    .app-stats-box ._count {
        font-size: 16px !important;
    }

    .MuiDialog-paper .app-reqr-modal-chart-content-inner .btn-close {
        padding: 0 !important;
        top: -380px !important;
        right: 0 !important;
    }

    .MuiDialog-paper .app-reqr-modal-chart-content-inner {
        position: unset;
    }

    .svg-inline--fa {
        padding-top: 2px !important;
    }

    .app-header-kyc {
        // flex-direction: row-reverse;
    }

    .app-header-kyc .pr-3.d-none.d-md-block {
        display: block !important;
        padding: 0 !important;
    }

    .kyc_title {
        width: auto;
        overflow: hidden;
        white-space: nowrap;
    }

    .w-250 {
        min-width: auto !important;
    }

    .MuiButton-root.btn-link-gst {
        margin-left: 0 !important;

    }

    body .btn-header-kyc {
        height: auto !important;
        padding: 0.2rem !important;
        margin-left: 1rem !important;
    }

    .group-accordian {
        height: auto !important;
    }

    .MuiDialog-paper.app-overview-modal .MuiGrid-root.MuiGrid-container {
        overflow-x: hidden !important;
        overflow-y: hidden;
    }

    // .MuiDialog-paper.app-overview-modal {
    //     pointer-events: all !important;
    // }

    .MuiDialog-paper .app-reqr-modal-chart-graph-col {
        background-color: #fafafa !important;
        border-bottom: 2px solid #d9d9d9 !important;
    }

    .MuiDialog-paper.app-overview-modal {
        max-width: calc(100% - 0px) !important;
        margin: 0 !important;
        border-radius: 0 !important;
    }

    .MuiGrid-root.pl-4,
    .MuiGrid-root.pr-4 {
        padding: 0 !important;
    }

    .cash-flow-responsive {
        width: 90%;
        margin-top: 2rem;

    }

    .tooltip-wrap {
        display: flex;
    }

    .tooltip-2 {
        top: 4px !important;
        padding: 2px !important;
    }

    .tooltip-wrap span.cash-line-chart-title.cursor_pointer {
        top: 4px !important;
        padding: 2px !important;
    }

    .MuiDialog-paper .app-reqr-modal-chart-graph-col .app-alerts {
        display: flex;
        align-items: start;
    }

    .app-price-slider-list .app-price-slider-info {
        padding-left: .8rem !important;
    }

    .desktop_kyc .app-header-kyc {
        display: none !important;
    }

    .mobile_kyc .app-header-kyc {
        display: flex !important;
        justify-content: space-between;
        padding-top: .8rem;
    }

    .mobile_kyc .app-header-kyc .MuiButton-label {
        font-size: 12px !important;
    }

    .h-inherit {
        height: 100vh !important;
        overflow-y: scroll !important;
        background-color: #fafafa !important
    }

    .card-header--title.font-size-xxl.font-monrope.text-base.font-weight-bold.pb-3 {
        padding-bottom: 0 !important;
    }

    .app-reqr-status-wrap.d-flex.align-items-center.justify-content-around {
        border-top: none !important;
    }

    .MuiGrid-root.pl-md-2.MuiGrid-item.MuiGrid-grid-lg-6 {
        border-top: 1px solid #E6E6E6;
    }

    .app-header {
        border-bottom: 1px solid #E6E6E6;
    }

    .prcie_advantage {
        top: 10px !important;
    }

    .price-advantage-tooltip .MuiPopover-paper {

        width: 100% !important;
        left: 4% !important;
        //margin-top: 1rem !important;
    }
}

@media (max-width:500px) {
    .MuiDialog-paper .app-reqr-modal-chart-content-inner .btn-close {
        top: -300px !important;
        right: 0 !important;
    }

    .app-price-slider-cost.d-flex.flex-column {
        width: 24%;
    }

    .kyc_title {
        font-size: auto;
    }

    .app-price-slider-list .app-price-slider-cost ._prev-price123 {
        font-size: .8rem;
    }

    .app-price-slider-list .app-price-slider-cost ._price {
        font-size: 1rem;
    }


    .MuiDialog-paper .app-reqr-modal-chart-graph-col .app-alerts {
        position: absolute;
        padding: 0 !important;
        top: 42px;
    }
}

@media (max-width:350px) {
    .MuiDialog-paper .app-reqr-modal-chart-content-inner .btn-close {
        top: -310px !important;
    }

    .MuiButton-root.btn-link-gst {
        font-size: .55rem !important;
    }
}

@media only screen and (min-width: 500px) and (max-width: 562px) {
    .MuiDialog-paper .app-reqr-modal-chart-content-inner .btn-close {
        top: -300px !important;
    }
}