//  Core

html,
body {
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: auto;
  height: 100%;
}

body {
  overflow-x: hidden;
}

.app-wrapper {
  display: flex;
  flex: 1;
  background: $white;
  overflow-x: hidden;
  transition: all 0.5s $app-sidebar-transition;
}

.app-main {
  display: flex;
  flex: 1 auto;
  min-width: 0;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
}

.page-header{
  padding: 1rem 2.5rem;
}

.app-content {
  flex: 1;
  display: flex;
  padding: 0;
  flex-direction: column;
  transition: all 0.5s $app-sidebar-transition;

  &--inner {
    overflow: hidden;
    padding: ($layout-spacer - ($spacer / 2));

    @include media-breakpoint-down(sm) {
      padding: $layout-spacer / 3;
    }

    display: flex;
    flex: 1;

    &__wrapper {
      display: block;
      width: 100%;
    }
  }
}

// Fixed sidebar

.app-sidebar-fixed {
  .app-content {
    padding-left: $sidebar-width;
  }
}

@include media-breakpoint-down(md) {
  .app-content {
    z-index: 500;
    padding-left: 0 !important;
  }
}

@include media-breakpoint-up(lg) {
  .app-sidebar-collapsed {
    .app-content {
      padding-left: $sidebar-width-collapsed;
    }
  }
}


.hidden{
  display: none;
}

.skelton-card {
  display: flex;
  flex-direction: column;
  height: 425px;

}
.top-card-1{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 284px;
  overflow: hidden;
}

@include media-breakpoint-down(md) {
  .top-card-1 {
    height: 180px;
  }
}


.circle-card {
  display: flex;
  height: 180px;
  width: 180px;
  background-color:whitesmoke;
  overflow: hidden;
  border-radius: 100%;
}
@include media-breakpoint-down(md) {
  .circle-card {
    height: 120px;
    width: 120px;
  }
}


.small-card{
  background-color:whitesmoke;
  overflow: hidden;
  border-radius: 5px;
  height: 100%;
  width: 45%;
}
.order-text-card{
  height: 10px;
  width: 10px;
  background-color:whitesmoke;

}
 
.card-footer-1 {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  height: 90px;
  flex-direction: row;
  align-items: center;
}

 

.loading::after {
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  background: -webkit-gradient(linear, left top,
      right top, from(transparent),
      color-stop(rgba(255, 255, 255, 0.2)),
      to(transparent));

  background: linear-gradient(90deg, transparent,
      rgba(255, 255, 255, 0.2), transparent);

  /* Adding animation */
  animation: loading 0.8s infinite;
}

/* Loading Animation */
@keyframes loading {
  100% {
    transform: translateX(100%);
  }
}
@keyframes pulse {
  50% {
    opacity: .5;
  }
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

