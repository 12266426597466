//  Core

body {
  .d-44 {
    height: 44px;
    line-height: 44px;
    width: 44px;
  }

  .height-280 {
    height: 260px;
    width: 400px;
  }

  .app-header-dots {
    overflow: hidden;
    min-height: 493px;
  }

  .icon-check2{
    width: 20px;
    height: 20px;
    background-image: url('../../../assets/images/icons/checkbox-success.png');
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    margin-right: 10px;
  }
  .btn-header-kyc{
    font-size: 14px;
    padding: 10px 16px;
    height: 42px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 170px;
    color: #000000 !important;
    font-weight: 600;
    &.btn-outline-danger {
      &:hover{
        background-color: #FEF1F2 !important;
        border-color: #EB1B1B;
      }
    }
    &.btn-outline-success{
      background-color: #EAFFF1;
      border-color: #28A745;
      &:hover{
        background-color: #EAFFF1;
        border-color: #28A745;
      }
    }
    .icon-info{
      width: 16px;
      height: 16px;
      background-image: url('../../../assets/images/icons/info-danger.png');
      background-repeat: no-repeat;
      background-size: contain;
      display: block;
    }
    .icon-check{
      width: 20px;
      height: 20px;
      background-image: url('../../../assets/images/icons/checkbox-success.png');
      background-repeat: no-repeat;
      background-size: contain;
      display: block;
    }
  }

  .app-kyc-popover{
    font-weight: 500;
  }

  .app-kyc-popover-box{
    padding: 18px 18px 16px;
  }

  .app-kyc-popover-info{
    margin-bottom: $spacer;
    color: $base;
    line-height: 19px;
    max-width: 483px;
    .icon-info{
      width: 24px;
      height: 24px;
      flex: 0 0 24px;
      display: block;
      background-image: url('../../../assets/images/icons/info-danger.png');
      background-repeat: no-repeat;
      background-size: contain;
      margin-right: 14px;
    }
  }
  .app-kyc-popover-link{
    margin-left: 34px;
    text-decoration: none;
    span{
      margin-right: 8px;
      font-weight: 500;
    }
    svg{
      transform: rotate(45deg);
      color: #004BCD !important;
    }
    .icon-redirect{
      width: 16px;
      height: 16px;
      flex: 0 0 16px;
      display: block;
      background-image: url('../../../assets/images/icons/redirect.png');
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  .MuiButton-root{
    &.btn-link-gst{
      // font-size: $font-size-lg;
      font-size: 16px;
      padding: 13px 0;
      color: #000000;
      &:hover{
        color: #000000;
      }
      .MuiButton-label{
        display: flex;
        align-items: center;
      }
      ._icon-wrap{
        margin-top: -3px;
        svg{
          font-size: 14px;
        }
      }
      ._number{
        font-weight: 600;
      }
    }
  }

  .app-gst-popover{
    font-family: $font-family-manrope;
    .MuiList-padding{
      padding-top: 0;
    }
    .MuiList-root{
      margin: 0;
      .MuiListItem-root{
        padding: 8px 16px;
        ._number{
          color: $base;
        }
        ._label{
          font-size: 12px;
          color: #9D9D9D;
          text-transform: uppercase;
          font-weight: 600;
        }
      }
    }
  }
  .app-gst-popover-top{
    border-bottom: 1px solid #E6E6E6;
    font-size: 14px;
    padding: 10px 16px;
    color: $base;
  }

  .btn-user{
    ._name{
      // font-size: 20px;
      font-size: 16px;
      color: #000000;
      padding-left: 14px;
    }
  }

  .popover-user{
    padding: 12px 16px 16px;
    .popover-user-info{
      flex-direction: column;
      align-items: flex-start;
      padding: 0 0 14px;
      margin-bottom: 8px;
      ._label{
        font-size: 12px;
        line-height: 15px;
        color: #667080;
        margin-bottom: 15px;
        display: block;
      }
      .popover-user-wrap{
        ._user-name{
          font-size: 14px;
          line-height: 17px;
          font-weight: 500;
          margin-left: 10px;
        }
        svg{
          font-size: 20px;
          color: #9D9D9D;
        }
      }
    }
  }

  .btn-logout{
    font-size: 14px;
    color: #443E3E;
    .icon-logout{
      width: 16px;
      height: 16px;
      background-image: url('../../../assets/images/icons/logout.png');
      background-repeat: no-repeat;
      background-size: contain;
    }
    svg{
      color: #443E3E;
    }
  }
}

@media (max-width: 767px){
  .btn-user{
    padding: 0 !important;
    position: absolute !important;
    right: 0;
    ._name{
      display: none;
    }
    .avatar-icon{
      font-size: 14px;
      &.d-40 {
        height: 30px !important;
        line-height: 30px !important;
        width: 30px !important;
      }
    }
  }

  body {
    .MuiButton-root{
      &.btn-link-gst{
        font-size: 13px;
        margin-left: 21px;
      }
    }
  }

  .app-gst-popover{
    .MuiList-root {
      .MuiListItem-root{
        padding: 10px 14px !important;
        span{
          font-size: 14px;
          font-family: $font-family-manrope;
          &._label{
            font-size: 11px;
          }
        }
      }
    }
  }
}
