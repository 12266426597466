.app-price-carousal-box {
    border-color: #E6E6E6;
    height: 100%;

    .card-header {
        padding: 20px 20px 16px;
        border-radius: 0.75rem;
    }

    .card-header--title {
        h3 {
            font-family: $font-family-manrope;
            font-size: 24px;
            font-weight: 700;
            color: #1D1B1B;
            margin-left: 16px;
            margin-left: 10px;
        }

        .btn-warning {
            font-weight: 500;
            border-radius: 8px;

            .btn-text {
                padding-left: 10px;
            }
        }
    }
}

.app-price-slider-list {
    font-family: $font-family-manrope;

    &.list-group-flush {
        border-top: 1px solid rgba(0, 0, 0, 0.125);

        .MuiListItem-root {
            padding: 16px 20px;
        }

        &:last-child {
            border-bottom: 1px solid rgba(0, 0, 0, 0.125);
        }
    }

    .app-price-slider-img-wrap {
        img {
            max-height: 54px;
        }
    }

    .app-price-slider-info {
        padding-left: 32px;

        ._name {
            font-weight: 700;
            color: #1D1B1B;
            margin-bottom: 7px;
            line-height: 22px;
        }

        ._quantity {
            font-size: 14px;
            font-weight: 500;
            color: #9D9D9D;
            line-height: 19px;
        }
    }

    .app-price-slider-cost {
        ._price {
            font-size: 20px;
            line-height: 27px;
            font-weight: 800;
            color: #1D1B1B;
            margin-bottom: 4px;
        }

        ._prev-price {
            font-size: 16px;
            line-height: 22px;
            font-weight: 600;
            color: rgba(59, 62, 101, 0.4);
            text-decoration: line-through;
        }

        ._prev-price123 {
            font-size: 16px;
            line-height: 22px;
            font-weight: 600;
            color: rgba(59, 62, 101, 0.4);
        }
    }
}

body {
    .MuiCard-root {
        .MuiCardContent-root {
            &.app-price-card-content {
                padding: 0 26px 24px;
                margin-bottom: 48px;
            }
        }
    }
}

.app-price-slider {
    margin-top: 48px;
    margin-bottom: 36px;
}

//sidebar css
.app-pending-doc-drawer {
    font-family: $font-family-manrope;

    .app-pending-doc-drawer-head {
        border-bottom: 1px solid #CECECE;
        padding: 36px 0 24px;
        margin: 0 24px;

        .app-pending-doc-drawer-title {

            .app-pending-doc-drawer-head-icon-wrap {
                border-radius: 6px;
                width: 48px;
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: center;

                .icon-file {
                    width: 24px;
                    height: 24px;
                    display: inline-flex;
                    background-image: url('../../images/icons/doc.png');
                    background-repeat: no-repeat;
                    background-size: contain;
                }
            }


            h2 {
                font-family: $font-family-manrope;
                font-weight: 600;
                font-size: 20px;
                line-height: 27px;
                padding-left: 16px;
                color: #1D1B1B;
            }
        }
    }

    .app-pending-doc-drawer-body-wrap {
        height: calc(100vh - 109px);
    }

    .app-pending-doc-drawer-body {
        padding: 16px 24px;

        .app-pending-doc-drawer-body-top {
            margin-bottom: 24px;

            .icon-info {
                width: 24px;
                flex: 0 0 24px;
                height: 24px;
                display: inline-flex;
                background-image: url('../../images/icons/info-danger.png');
                background-repeat: no-repeat;
                background-size: contain;
                margin-top: 3px;
            }

            span {
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #1D1B1B;
                padding-left: 12px;
                max-width: 465px;
            }
        }

        .app-doc-drawer-list {
            &.MuiList-padding {
                padding: 0;
            }

            .app-doc-drawer-list-item {
                box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25);
                border-radius: 8px;
                background-color: $white;
                padding: 16px;
                margin-bottom: 12px;

                ._name {
                    font-size: 16px;
                    line-height: 22px;
                    color: #000000;
                }

                ._status-wrap {
                    .icon {
                        width: 16px;
                        height: 16px;
                        display: inline-flex;
                        background-repeat: no-repeat;
                        background-size: contain;
                    }

                    ._info {
                        background-image: url('../../images/icons/info-danger.png');
                    }

                    ._checkmark {
                        background-image: url('../../images/icons/checkbox-success.png');
                    }

                    span {
                        font-size: 14px;
                        line-height: 19px;
                        color: #9D9D9D;
                        padding-left: 4px;
                    }
                }
            }
        }
    }


}


.app-approved-quotes-drawer {
    .app-app-approved-quotes-head {
        padding: 32px 0;
        margin: 0 32px;
        border-bottom: 1px solid #CECECE;

        .app-app-approved-quotes-title {
            ._icon-wrap {
                width: 48px;
                height: 48px;
                background-color: $white;
                border-radius: 6px;
                display: flex;
                align-items: center;
                justify-content: center;

                .icon-tick {
                    width: 32px;
                    height: 32px;
                    display: block;
                    background-image: url('../../images/icons/tick-success.png');
                    background-repeat: no-repeat;
                    background-size: contain;
                }
            }

            ._info-wrap {
                margin-left: 16px;

                h2 {
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 24px;
                    color: #1D1B1B;
                    margin-bottom: 8px;
                }

                span {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    color: #443E3E;
                }
            }
        }
    }

    .app-app-approved-quotes-body-wrap {
        height: calc(100vh - 121px);
    }

    .app-approved-quotes-drawer-body {
        padding: 30px 32px;

        .app-quotes-drawer-list {
            .app-quotes-drawer-list-item {
                background-color: $white;
                box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25);
                border-radius: 8px;
                margin-bottom: 16px;
                padding: 18px 24px;

                ._name {
                    font-size: 18px;
                    line-height: 24px;
                    color: #443E3E;
                    margin-bottom: 12px;

                    ._number {
                        font-weight: 600;
                        color: #2B4B45;
                    }
                }

                ul {
                    li {
                        list-style-type: none;
                        margin-right: 20px;
                        display: flex;
                        align-items: center;

                        &:last-child {
                            margin-right: 0;
                        }

                        .icon {
                            width: 20px;
                            height: 20px;
                            background-repeat: no-repeat;
                            background-size: contain;
                            display: block;

                            &._calendar {
                                background-image: url(../../../assets/images/icons/calendar.png);
                            }

                            &._location {
                                background-image: url(../../../assets/images/icons/location.png);
                            }
                        }

                        span {
                            font-size: 15px;
                            line-height: 19px;
                            padding-left: 8px;
                            color: #1D1B1B;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 991px) {
    .app-price-carousal-box {
        .card-header--title {
            h3 {
                font-size: 24px;
                margin-left: 0;
            }
        }

    }
}

@media (max-width: 767px) {
    .MuiButton-text {
        padding: 8px 10px !important;
        font-size: 14px !important;
        border-radius: 4px !important;
    }

    .app-price-carousal-box {
        .card-header {
            padding: 12px;
        }

        .card-header--title {
            h3 {
                font-size: 16px;
                margin-left: 0;
            }
        }
    }

    .app-pending-doc-drawer {
        .app-pending-doc-drawer-head {
            padding: 12px 0;
            margin: 0 12px;

            .app-pending-doc-drawer-title {
                .icon-file {
                    width: 22px;
                    height: 22px;
                }

                h2 {
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }

        .app-pending-doc-drawer-body {
            padding: 12px;

            .app-pending-doc-drawer-body-top {
                margin-bottom: 16px;

                .icon-info {
                    width: 20px;
                    flex: 0 0 20px;
                    height: 20px;
                }

                span {
                    font-size: 14px;
                    line-height: 20px;
                    padding-left: 8px;
                }
            }

            .app-doc-drawer-list {
                .app-doc-drawer-list-item {
                    padding: 12px;

                    ._name {
                        font-size: 14px;
                        line-height: 20px;
                    }

                    ._status-wrap {
                        span {
                            font-size: 12px;
                            line-height: 16px;
                            padding-left: 4px;
                        }
                    }
                }
            }
        }
    }

    .app-price-slider {
        margin-top: 12px;
        margin-bottom: 12px;

        .slick-prev,
        .slick-next {
            background-size: 8px;
        }

        .slick-next {
            margin-right: -30px;
        }

        .slick-prev {
            margin-left: -30px;
        }
    }

    .app-price-slider-list {
        &.list-group-flush {
            .MuiListItem-root {
                padding: 8px 12px;
            }
        }

        .app-price-slider-info {
            ._name {
                font-size: 14px;
                line-height: 18px;
                margin-bottom: 5px;
            }

            ._quantity {
                font-size: 12px;
                line-height: 16px;
            }
        }

        .app-price-slider-cost {
            ._price {
                font-size: 18px;
                line-height: 24px;
            }

            ._prev-price {
                font-size: 14px;
                line-height: 18px;
            }
        }
    }

    body {
        .MuiCard-root {
            .MuiCardContent-root {
                &.app-price-card-content {
                    padding: 0 12px;

                }
            }
        }
    }

    .app-approved-quotes-drawer {
        .app-app-approved-quotes-head {
            padding: 12px 0;
            margin: 0 12px;

            .app-app-approved-quotes-title {
                ._icon-wrap {
                    width: 32px;
                    height: 32px;

                    .icon-tick {
                        width: 20px;
                        height: 20px;
                    }
                }

                ._info-wrap {
                    margin-left: 12px;

                    h2 {
                        font-size: 16px;
                        line-height: 20px;
                        margin-bottom: 4px;
                    }

                    span {
                        font-size: 13px;
                        line-height: 18px;
                    }
                }
            }

        }

        .app-approved-quotes-drawer-body {
            padding: 12px;

            .app-quotes-drawer-list {
                .app-quotes-drawer-list-item {
                    padding: 12px;
                    margin-bottom: 12px;

                    ._name {
                        font-size: 14px;
                        line-height: 19px;
                        margin-bottom: 10px;
                    }

                    ul {
                        li {
                            .icon {
                                width: 18px;
                                height: 18px;
                            }

                            span {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
}