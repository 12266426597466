.app-stats-grid-container{
    &.MuiGrid-spacing-xs-6{
        width: calc(100% + 24px);
        margin: 0 -12px;
        & > .MuiGrid-item{
            padding: 0 12px;
        }
    }
}
.app-stats-box{
    border: 2px solid #F3F3F3;
    border-radius: 8px;
    .app-stats-box-top{
        margin-bottom: 10px;
    }
    &.MuiCard-root{
        .MuiCardContent-root{
            padding: 16px 16px 16px 32px;
        }
    }
    
    ._label{
        font-family: $font-family-manrope;
        // font-size: $font-size-md;
        font-size: 16px;
        font-weight: 500;
        color: rgba(#3B3E65, 0.6);
        line-height: 24px;
    }

    ._count{
        font-family: $font-family-manrope;
        // font-size: 32px;
        font-size: 26px;
        font-weight: 800;
        // line-height: 44px;
        line-height: 36px;
        color: #1D1B1B;
    }
    .bg-neutral-success{
        background-color: rgba(0, 186, 52, 0.15) !important;
    }
    .bg-neutral-warning{
        background-color: rgba(255, 191, 1, 0.15) !important;
    }

    .icon{
        width: 28px;
        height: 28px;
        display: block;
        background-repeat: no-repeat;
        background-size: contain;
        &.icon-overdue{
            background-image: url('../../../assets/images/icons/timer-overdue.png');
        }
        &.icon-currency-rupee{
            background-image: url('../../../assets/images/icons/currency-rupee.png');
        }
        &.icon-timer{
            background-image: url('../../../assets/images/icons/timer.png');
        }
        &.icon-users-person{
            background-image: url('../../../assets/images/icons/users-person.png');
        }

    }
}


@media (max-width: 1099px){
    .app-stats-grid-wrap{
        margin-bottom: 0;
    }

    .app-stats-grid-container{
        &.MuiGrid-spacing-xs-6 {
            & > .MuiGrid-item{
                margin-bottom: 24px;
            }
        }
    }
}

@media (max-width: 767px){
    .app-stats-box{
        &.MuiCard-root {
            .MuiCardContent-root{
                padding: 12px;
            }
        }
        ._label{
            font-size: 13px;
            line-height: 17px;
        }
        .btn-icon{
            height: 36px !important;
            line-height: 36px !important;
            width: 36px !important;
        }
        .icon{
            width: 20px;
            height: 20px;
        }
        ._count{
            font-size: 16px;
            line-height: 24px;
        }
    }

    .app-stats-grid-wrap{
        margin-bottom: 12px;
    }
    .app-stats-grid-container{
        &.MuiGrid-spacing-xs-6 {
            width: calc(100% + 16px);
            margin: 0 -8px;
            & > .MuiGrid-item{
                padding: 0 8px;
                margin-bottom: 16px;
            }
        }
    }
    
}