//  Core

.app-header {
  padding: ($layout-spacer / 2) ($layout-spacer - ($spacer / 2));
  background: $header-bg;
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
  // z-index: 999;
  transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);

  &--pane {
    display: flex;
    align-items: center;
  }


  @include media-breakpoint-down(md) {


    .app-sidebar-fixed:not(.app-header-fixed) {
      .app-header {
        margin-left: $sidebar-width;
      }
    }
  }

  &--shadow {
    box-shadow: $header-shadow;
  }

  &--opacity-bg {
    background: $header-bg-transparent;
  }

  .hamburger-inner,
  .hamburger-inner:after,
  .hamburger-inner:before {
    background: $primary !important;
  }

  .toggle-mobile-sidebar-btn {
    display: none;
    padding: 0;

    .MuiButton-label {
      display: flex;
    }
  }

  &:hover {
    background: $header-bg !important;
  }
}

// Fixed header

.app-header-fixed {
  .app-header {
    // position: fixed;
    // left: 0;
    // right: 0;
    // margin-left: 0;
  }
}

// Fixed sidebar

@include media-breakpoint-up(lg) {
  .app-sidebar-fixed:not(.app-header-fixed) {
    .app-header {
      margin-left: $sidebar-width;
    }
  }
}

// Collapsed sidebar

@include media-breakpoint-up(lg) {
  .app-sidebar-collapsed:not(.app-header-fixed) {

    .app-header {
      margin-left: $sidebar-width-collapsed;
      left: 0;
    }

    &.app-header-fixed {
      .app-header {
        left: $sidebar-width-collapsed;
        margin-left: 0;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .app-sidebar-collapsed {
      &.app-header-fixed {
        .app-header {
          left: $sidebar-width-collapsed;

        }
      }
    }
  }
}

// Responsive

@include media-breakpoint-down(md) {
  .app-header {
    left: 0;
    flex-shrink: 0;
    padding: 0 ($layout-spacer / 3);
    // display: none !important;

    .toggle-mobile-sidebar-btn {
      display: flex;
    }
  }
}

@media (max-width: 1199px) {
  .app-header {
    padding: 20px 2.5rem;
  }

  .app-header-fixed {
    .app-content {
      // padding-top: 90.5px;
    }
  }
}

@media (max-width: 767px) {
  .DonutDummyImage {
    width: 134px !important;
    margin-top: 25px !important;
    margin-bottom: 18px !important;
  }

  .app-header {
    padding: 12px 0;
    margin: 0 16px;
    // border-bottom: 1px solid #E6E6E6;

  }

  .app-header-fixed {
    .app-header {
      // left: 16px;
      // right: 16px;
      // margin-left: 0;
      // margin-right: 0;
    }
  }

  .app-sidebar-logo {
    position: absolute;
    left: 0;

    &>img {
      max-height: 24px !important;

      ~span {
        margin-left: 0;
      }
    }
  }

  .app-header-nav-pane {
    flex-grow: 1;
    justify-content: center;
    padding: 1rem;
  }
}



button.incomplete-hover:before {
  transition: 0.5s all ease;
  position: absolute;
  top: 0;
  left: 50%;
  right: 50%;
  bottom: 0;
  opacity: 0;
  content: '';
  background-color: #eb1b1b26;
  z-index: -1;
}

button.complete-hover:before {
  transition: 0.5s all ease;
  position: absolute;
  top: 0;
  left: 50%;
  right: 50%;
  bottom: 0;
  opacity: 0;
  content: '';
  background-color: #08ff4e32;
  z-index: -1;
}

//  button.incomplete-hover:focus,button.complete-hover:focus{
//   background-color:transparent!important;
//  }
button.incomplete-hover:hover,
button.incomplete-hover:focus,
button.complete-hover:hover,
button.complete-hover:focus {
  color: white;
  border-radius: 0.38rem !important;

}

button.incomplete-hover:hover:before,
button.incomplete-hover:focus:before,
button.complete-hover:hover:before,
button.complete-hover:focus:before {
  transition: 0.5s all ease;
  left: 0;
  right: 0;
  opacity: 1;
  border-radius: 0.38rem !important;

}

button.incomplete-hover:active {
  transform: scale(0.9);
}

.app-price-carousal-box {
  border: 2px solid #F3F3F3 !important;
}

// .app-dashboard-map-wrap .MuiCard-root.card-box {
//   height: 100%!important;
// }
.MuiGrid-root.MuiGrid-item.MuiGrid-grid-lg-5 .MuiPaper-root.MuiCard-root.app-card-box.card-box.bordered-card-box.shadow-none.overflow-visible.MuiPaper-elevation1.MuiPaper-rounded .card-header.border-0 .MuiCardContent-root {
  height: 400px !important;
}